<template>
  <div>
    <b-table
      small
      :fields="fields"
      :items="items"
      responsive="sm"
      @row-clicked="showData"
    >
      <template #cell(id)="data">
        P-{{String(data.value).padStart(5,'0')}}
      </template>
      <template #cell(Fecha)="data">
       {{new Date(data.value).toLocaleDateString('es-es',{ year: 'numeric',month: '2-digit',day: '2-digit',})}}
      </template>
      <template #cell(Estado)="data">
        <b-badge :variant="status[data.value].color">
          {{ status[data.value].title }} 
        </b-badge>
      </template>
    </b-table>
  </div>
</template>

<script>
import { 
  BTable,
  BBadge,
  BSidebar,
  BFormInput,
  BInputGroup,
  BInputGroupAppend, 
  VBToggle,
  BButton,
  VBModal,
  BAlert,
  BCol,
  BRow, 
} from 'bootstrap-vue'
import { mapState } from 'vuex';

export default {
  components: {
    BTable,
    BBadge,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BSidebar,
    BButton,
    BAlert,
    VBModal,
    BCol,
    BRow,    
  },
  directives: {
    'b-toggle': VBToggle
  },
  data() {
    return {
      status : [
        {title : 'Nuevo', color :  'light-dark' },
        {title : 'Solicitado', color :  'warning' },
        {title : 'Aceptado', color : 'success'},
        {title : 'Cancelado', color : 'danger'}
        ],
      fields: [
        'Referencia','Fecha','Fabricante','Presupuesto','Estado'
      ],
      items: [],
      order : {},
      elements : [],
      input_accept : false,
      input_ref : null,
      open : false
    }
  },
  mounted () {
    this.$http.get("/api/getOrders?user_id=" + this.user.id).then((response) => {
      this.items = response.data
    }); 
  },
  methods: {
    showData(record, index)
    {
      this.input_accept = false
      this.input_ref = null    
      this.$http.get("/api/getOrder?id=" + record.id).then((response) => {
        this.order = response.data
        this.order.fabricante = {title : response.data.name, id : response.data.manufacturer_id }
        this.$store.commit('ventanas/setOrder', this.order)
        this.$router.push('/order')
      });
      return record
    },
  },
    computed: {
    ...mapState({
      user: state => state.auth.user,   
    }),
  }    
}
</script>
<style>
 .img-side {
   height: 40px;
 }
</style>
 

 